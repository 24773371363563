<template>
  <div>
    <b-row class="mb-2">
      <b-col md="12" style="display: flex; gap:10px;">
        <export-button
          :filter="exportFilter"
          url="/export-center-horses-history"
          :disabled="loadingData || !items.length"
          file-title="Horses report.xlsx"
        />
        <b-button v-b-modal.modal-hierachy v-if="width<768">
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col
          md="3"
          class="d-flex align-items-center justify-content-center mb-1"
        >
          <b-form-group :label="$t('labels.ticket_id')">
            <b-form-input
              v-model="filterSelect.ticketId"
              class="d-inline-block mr-1"
              @input="inputSelect"
              :placeholder="$t('labels.ticket_id')"
            />
          </b-form-group>
          <div style="padding-left:10px;">
            <b-button
              variant="primary"
              type="button"
              class="btn btn-icon rounded-circle mr-1 btn-primary btn-sm"
              v-b-tooltip.hover.top="$t('labels.search')"
              @click="modalSearchTicketId()"
              size="sm"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.typeuser')">
            <v-select
              :value="$store.value"
              v-model="filterSelect.userType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              label="key"
              @input="inputSelect"
              :reduce="(status) => status.value"
              :placeholder="$t('select_type')"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.racecourses')">
            <v-select
              :value="$store.id"
              v-model="filterSelect.raceCourses"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="ticketOptions"
              label="name"
              @input="inputSelect"
              :reduce="(product) => product.id"
              :placeholder="$t('select_racecourse')"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.status')">
            <v-select
              :value="$store.id"
              v-model="filterSelect.selectStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              label="name"
              @input="inputSelect"
              :reduce="(status) => status.id"
              :placeholder="$t('select_status')"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateFrom"
              @input="inputSelect"
              class="form-control"
              :config="{
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          enableTime: true,
                          wrap : true,
                          plugins: [
                             new yearDropdownPlugin({
                               yearStart: 100,
                               yearEnd: 0
                            })
                           ],
                        }"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateTo"
              @input="inputSelect"
              class="form-control"
              :config="{
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          enableTime: true,
                          wrap : true,
                          plugins: [
                             new yearDropdownPlugin({
                               yearStart: 100,
                               yearEnd: 0
                            })
                           ],
                        }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col md="10">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal
            id="modalResponse"
            title="PopUp Response"
            cancel-variant="outline-secondary"
            scrollable
          >
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>

          <!-- search -->
          <b-col
            md="3"
            class="d-flex align-items-center justify-content-center mb-1"
          >
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              :placeholder="$t('filter')"
            />
          </b-col>
          <!-- table -->
          <b-table
            md="9"
            :items="items"
            :fields="fields"
            class="scroll"
            responsive
          >
            <template #cell(ticketId)="data">
              <span
                class="text-muted mr-1"
                @click="modalTicketId(data.item.ticketId, data.item.session)"
              >
                <b-button
                  variant="outline-primary"
                  >{{ data.item.ticketId }}</b-button
                >
              </span>
            </template>
            <template #cell(amountBet)="data">
              {{ data.item.amountBet | currency({ symbol: "" }) }}
            </template>
            <template #cell(amountPrize)="data">
              {{ data.item.amountPrize | currency({ symbol: "" }) }}
            </template>
            <template #cell(amountRetired)="data">
              {{ data.item.amountRetired | currency({ symbol: "" }) }}
            </template>
            <template #cell(date_tickets)="data">
              {{ data.item.date_tickets | moment }}
            </template>
            <template #cell(date_payment)="data">
              <span v-if="data.item.date_payment != null">
                {{ data.item.date_payment | moment }}</span
              >
              <span v-else> {{ "-" }}</span>
            </template>
            <template #cell(ticketStatusName)="data">
              <span
                v-if="data.item.ticketStatusName == 'Pendiente'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #03a9f4; color:#FFFFFF;"
              >
                 {{ $t('status.pending') }}</span
              >
              <span
                v-else-if="data.item.ticketStatusName == 'Perdido'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
              >
                {{ $t('status.lost') }}</span
              >
              <span
                v-else-if="data.item.ticketStatusName == 'Ganado'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
              >
                {{ $t('status.won') }}</span
              >
              <span
                v-else-if="data.item.ticketStatusName == 'Anulado'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
              >
                {{ $t('status.null_bet') }}</span
              >
              <span
                v-else-if="data.item.ticketStatusName == 'Pagado'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
              >
                {{ $t('status.paid') }}</span
              >
              <span
                v-else-if="data.item.ticketStatusName == 'Vencido'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
              >
                {{ $t('status.expired') }}</span
              >
              <span
                v-else-if="data.item.ticketStatusName == 'Devolucion'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
              >
                {{ $t('status.return') }}</span
              >
              <span
                v-else-if="data.item.ticketStatusName == 'En juego'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #ffb300; color:#FFFFFF;"
              >
                {{ $t('status.ingame') }}</span
              >
            </template>
          </b-table>
          <b>{{$t('totals')}}</b>
          <!--<b-pagination
            v-model="page"
            :total-rows="totalRows"
            :per-page="limit"
            :simple="true"
            :hide-goto-end-buttons="true"
          ></b-pagination>-->
        </b-card>
      </b-col>
      <b-col class="p-0" md="2">
        <b-modal
          no-body
          id="modal-hierachy"
          hide-footer
          :title="$t('agent_list')"
          v-if="width<768"
        >
          <HierarchyUsers
            @selectUser="showData"
            ref="HerarchiComponent"
          />
        </b-modal>
        <b-card no-body class="p-0 py-1" v-else>
          <HierarchyUsers @selectUser="showData" />
        </b-card>
        <b-modal
          no-body
          ref="my-modal"
          :title="$t('detail_ticket')"
          hide-footer
        >
          <b-card-text
            style="display:flex;justify-content:center;padding:20px"
            v-if="img"
          >
            <iframe style="width:300px; height:400px" :srcdoc="img"></iframe>
          </b-card-text>
          <b-card-text v-else>
            <center>
              <h1>{{$t('no_ticket')}}</h1>
            </center>
          </b-card-text>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import axios from '@/libs/axios';
import axiosDef from 'axios';
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import ExportButton from "@/views/pages/export/ExportButton.vue";

export default {
  components: {
    ExportButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    HierarchyUsers
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loadingData: false,
      dataItems: [],

      dataIdUser: [],
      pages: [20, 40, 60],
      search: "",
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      customerOptions: [],
      currencyssOptions: [],
      statusOptions: [],
      ticketOptions: [],
      filterSelect: {
        ticketId: "",
        raceCourses: '',
        selectStatus: '',
        userType: '',
        dateFrom: moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      dataReportHistory: [],
      img: "",
      exportFilter: {},
      width: window.innerWidth,
    };
  },

  computed: {
    fields() {
      return [
        { key: "raceId", label: this.$t('number') },
        { key: "ticketId", label: this.$t('labels.ticket_id') },
        { key: "username", label: this.$t('labels.userName') },
        { key: "racecourseName", label: this.$t('labels.racecourses') },
        { key: "raceNumber", label: this.$t('race') },
        { key: "racecourseTypeName", label: this.$t('type') },
        { key: "amountBet", label: this.$t('labels.amount') },
        { key: "amountPrize", label: this.$t('prize') },
        { key: "amountRetired", label: this.$t('refund') },
        { key: "date_tickets", label: this.$t('createdDate') },
        { key: "date_payment", label: this.$t('paymentDate') },
        { key: "ticketStatusName", label: this.$t('labels.status') }
      ]
    },

    typeOptions() {
      return [
        { key: this.$t('tabs.agent'), value: "Agent" },
        { key: this.$t('tabs.player'), value: "Player" }
      ]
    },

    productsOptions() {
      return this.$store.getters['user/productsUserOptions']
    },
    items() {
      if (this.dataItems != undefined) {
        return this.dataItems.filter(item => {
          return this.checkUsername(item) || this.checkTicket(item);
        })
      }
    },
  },
  // add filters
  filters: {
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },

  methods: {
    yearDropdownPlugin,

    checkUsername(item) {
      return item.username.toLowerCase().includes(this.search.toLowerCase())
    },
    checkTicket(item) {
      if (item.ticketId != "0")
        return item.ticketId.toString().toLowerCase().includes(this.search.toLowerCase())
    },

    modalSearchTicketId() {
      let id = this.dataIdUser;
      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL
        const params = {
          "userId": id,
          "whitelabel": this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
          "clientId": this.$store.state.whitelabelCurrencyNabvar.whitelabel.client,
          "currency": this.$store.state.whitelabelCurrencyNabvar.currency,
          "ticketId": this.filterSelect.ticketId,
          "racecourseId": '0',
          "statusId": '0',
          "date": [
            this.filterSelect.dateFrom,
            this.filterSelect.dateTo,
          ],
          "userType": this.$store.state.whitelabelCurrencyNabvar.userInfo.typeUser
        }
        axios
          .post(`${VUE_APP_URL}/centerhorses/report/ticket/history`, params)
          .then(r => {
            let Ticket = r.data.data
            this.modalTicketId(Ticket[0].ticketId, Ticket[0].session)
          })
          .catch(error => console.log(error.response))

      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching ticket",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    modalTicketId(id, session) {
      console.log(`${process.env.VUE_APP_URL}/ticket/${id}/html?nopaid=1&detail=false&new=true&token=${session}`);

      axiosDef
        .get(`${process.env.VUE_APP_CENTER_HORSES_URL}/ticket/${id}/html?nopaid=1&detail=false&new=true&token=${session}`)
        .then(r => {
          console.log(r)
          this.img = r.data
          if (this.img != "NO TICKET") {
            this.$refs['my-modal'].show()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching ticket",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(error => {
          console.log(error)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching ticket",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
    },
    /*List - Index*/
    async showData({ id }) {
      this.dataIdUser = id
      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL
        let date =  { dateFrom: new Date(this.filterSelect.dateFrom), dateTo: new Date(this.filterSelect.dateTo) }
        const params = {
          "userId": id,
          "whitelabel": this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
          "clientId": this.$store.state.whitelabelCurrencyNabvar.whitelabel.client,
          "currency": this.$store.state.whitelabelCurrencyNabvar.currency,
          "ticketId": this.filterSelect.ticketId,
          "racecourseId": this.filterSelect.raceCourses,
          "statusId": this.filterSelect.selectStatus,
          "date": [
            date.dateFrom.toISOString(),
            date.dateTo.toISOString()
          ],
          "userType": this.$store.state.whitelabelCurrencyNabvar.userInfo.typeUser
        }
        this.exportFilter = params
        const result = await axios.post(`${VUE_APP_URL}/centerhorses/report/ticket/history`, params)
        this.dataItems = result.data.data
        this.Totals = result.data.totals
        this.dataItems.forEach(element => {
          element.date_tickets = element.date_tickets + ' UTC'
        });
        return result
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    async selectUserHierarchy(id) {
      const currency = this.$store.state.whitelabelCurrencyNabvar.currency
      //const whitelabel = this.$store.state.whitelabelCurrencyNabvar.whitelabel._id
      const whitelabel = 248
      const VUE_APP_URL = process.env.VUE_APP_URL
      const params = {
        "id": id,
        "currency": currency,
        "whitelabel": whitelabel
      }
      let result = await axios
        .post(`${VUE_APP_URL}/treeUsers`, params)
        .then(r => r.data == "" ? 0 : r.data)
        .catch(error => console.log(error.response))
      return result

    },
    onPageChange(page) {
      console.log(page, "page");
      this.serverParams.page = page;
      this.showData();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.limit = limit;
      this.showData();
    },
    inputSelect() {
      let id = this.dataIdUser

      this.showData({ id });
    },

  },
  created() {
    this.width = window.innerWidth;
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
  mounted() {
    const VUE_APP_URL = process.env.VUE_APP_URL
    const params = {
      "whitelabelId": this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
    }
    axios
      .post(`${VUE_APP_URL}/centerhorses/ticketStatus`,params)
      .then(r => this.statusOptions = r.data)
      .catch(error => console.log(error.response))

    axios
      .post(`${VUE_APP_URL}/centerhorses/racecourses`,params)
      .then(r => this.ticketOptions = r.data)
      .catch(error => console.log(error.response))
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
